.scheduleCalendarHeader {
    margin-left: 30px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topTile {
    width: 7%;
    height: auto;
    border-radius: 16px;
    opacity: 1;
    background-origin: padding-box;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.date {
    font-weight: bold;
}

.topTileItem {

    margin-top: 12px;
    margin-bottom: 12px;
}

.days {
    font-weight: 500;
}

.topTileWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    font-family: 'poppins';
    font-size: 0.9rem;
}

.description {
    width: 8%;
    height: auto;
    border-radius: 10px;
    font-weight: 500;
    opacity: 1;
    background-origin: padding-box;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ScheduleCalendarEtcTile {
    width: 7%;
    height: 40px;
    border-radius: 10px;
    opacity: 1;
    background-origin: padding-box;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.ScheduleCalendarIrrigationTile {
    width: 7%;
    height: 24px;
    border-radius: 4px;
    opacity: 1;
    background-origin: padding-box;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ScheduleCalendarEtcDescription {
    width: 8%;
    height: 40px;
    border-radius: 16px;
    font-weight: 500;
    opacity: 1;
    background-origin: padding-box;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.rainText {
    font-size: 0.8rem;
}

.sunIcon {
    margin-top: 0px;
}

.naIcon {
    margin-top: 0px;
}

.rainIcon {
    margin-top: 5px;
}

.temperature {
    font-weight: 600;
    margin-bottom: 30px;
}

.bodyWrapper {
    width: 100%;
}

.options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.legendButton {
    margin-right: 30px;
}

.legendSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    font-family: 'poppins';
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
}

.legendLeft {
    margin-left: 30px;
}

.legendRight {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.greenBall {
    background-color: #2D9C41;
    width: 10px;
    height: 10px;
    border: 1px;
    border-color: #707070;
    border-radius: 50%;
    margin-right: 4px;
}

.blueBall {
    background-color: #1085FD;
    width: 10px;
    height: 10px;
    border: 1px;
    border-color: #707070;
    border-radius: 50%;
    margin-right: 4px;
}

.redBall {
    background-color: #E22C29;
    width: 10px;
    height: 10px;
    border: 1px;
    border-color: #707070;
    border-radius: 50%;
    margin-right: 4px;
}

.greyBall {
    background-color: #979797;
    width: 10px;
    height: 10px;
    border: 1px;
    border-color: #707070;
    border-radius: 50%;
    margin-right: 4px;
}

.legendItem {
    display: inline-flex;
    flex-direction: row;
    align-items: baseline;
    margin-right: 30px;
}

.firstSubHeadder {
    margin-left: 10px;
    font-weight: 300;
    font-size: 0.9rem;
    font-weight: 600;
}

.secondSubHeadder {
    margin-left: 10px;
    font-size: 0.9rem;
    font-weight: 600;
}

.secondSubHeadder {
    margin-left: 10px;
    font-weight: 300;
    font-size: 0.9rem;
}

.cardHeaderLeft {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.cardHeaderRight {
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.cardHeaderRight .firstSpan {
    font-weight: 300;
    font-size: 0.9rem;
    margin-right: 5px;
}

@media only screen and (max-width: 1000px) {
    .bodyWrapper {
        overflow: scroll;
    }
}

@media only screen and (max-width: 1000px) {

    .topTileWrapper {
        width: 1500px;
    }

}

@media only screen and (max-width: 1600px) {
    .rainIcon {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sunIcon {
        margin-top: 3px;
        margin-bottom: 18px;
    }

    .naIcon {
        margin-top: 4px;
        margin-bottom: 21px;
    }
}
.stressPercent {
	display: flex;
	direction: column;
	justify-content: space-between;
	gap: 10px;
	margin-right: 10px;
	align-items: center;
}

.irrigationThresholdText {
	font-size: 0.9rem;
	font-weight: 300;
	margin-right: 5px;
}
.RowHeader {
    margin-left: 30px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fieldDetailBody {
    margin-left: 0px;
    margin-right: 0px;
}